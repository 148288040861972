@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Rajdhani:wght@300;400;500;600;700&display=swap');
@font-face {
    font-family: 'cambria';
    font-style: normal;
    font-weight: 400;
    src: local(''),
      url('./../fonts/cambria.ttf') format('ttf'),
  }
/* 
font-family: 'Bebas Neue', sans-serif;
font-family: 'Rajdhani', sans-serif; */
:root{
    --primary__color : #071b6d;
    --secondary__color: #96dffd;
    --third__color: #5caee8;
    --text__color: #434040;
    --heading_color: #000;
    --body_font__family:  'Poppins', sans-serif;
    --heading_font__family: 'Poppins', sans-serif;
    --footer_bg: #e6e6e6;
}

a{
    color: var(--third__color);
    text-decoration: none;
    cursor: pointer;
    
}
.main-content{
    padding-bottom: 2.5rem;
}
.main-content h4 {
    margin-bottom: 20px;
}
.main-content li ol {
    padding-top: 10px;
    list-style: circle;
}
.main-content li {
    margin-bottom: 20px;
}
/* scrollbar width */
body::-webkit-scrollbar {
    width: 10px;
}
.bg-semiLight {
    background: #d7f3fe;
}
/* Track */
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
/* Handle */
body::-webkit-scrollbar-thumb {
    background: var(--primary__color);
    border-radius: 10px;
}
body{
    font-family: var(--body_font__family);
    color: var(--text__color);
    font-size: 1rem;
    font-weight: 400;
    overflow-x: hidden;
}
.uploadFile span label {
    padding: 10px 20px 10px 50px;
    width: 100%;
}
.inner-btn-modal button.btn-close {
    top: 27px;
    right: 22px;
}
h1,h2,h3,h4,h5,h6{
    font-family: var(--heading_font__family);
    color: var(--heading__color);
    font-weight: 600;
}
.heading-medium h1,.heading-medium h2, .heading-medium h3,.heading-medium h4,.heading-medium h5,.heading-medium h6{
    font-weight: 500;
}
.white-box.borderd {
    border: 2px solid #ccc8;
}
.uploadFile span:before {
    content: "";
    background: url(./../Images/cloud-computing.png);
    width: 24px;
    height: 24px;
    position: absolute;
    left: 15px;
    top: 10px;
    object-fit: contain;
    background-repeat: no-repeat;
}

.uploadFile span {
    position: relative;
    display: inline-block;
    width: 100%;
    border: 1px dashed #ccc;
    border-radius: 4px;
    margin-top: 5px;
}

.uploadFile span input {
    display:none;
}
button.btn.btn-link {
    color: #000;
    text-decoration: none;
}
h5, .h5 {
    line-height: 1.8rem;
}
.mh-100vh{
    min-height: calc(100dvh - 77px);
}
.mh-100vh.center-content {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-primary{
    color: var(--primary__color) !important;
}
section{
    padding: 65px 0;
    overflow: hidden;
    max-width: 100dvw;
}
section#online-store {
    padding-top: 75px;
}
.home section {
    min-height: calc(100vh - 76px) !important;
}
.slickCenterMode{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.rating {
    margin-bottom: 0.3rem;
    white-space: nowrap;
}
.price {
    font-weight: 500;
    padding-top: 5px;
}
.rating img {
    width: 14px;
    height: 14px;
    object-fit: contain;
    margin: 2px;
}
.section-heading {
    padding-bottom: 2rem;
}
.section-title{
    color: var(--primary__color);
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 2.6rem;
}
.main-title h2 {
    color: var(--primary__color);
    font-size: 2.8rem;
    font-weight: 700;
    max-width: 850px;
    margin: 0 auto 15px;
}
.main-title h2 span {
    font-weight: 400;
    color: #000;
}
.scan-item p {
    max-width: 173px;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0 auto;
}
.large-container .container {
    max-width: 1200px;
}
.section-heading p{
    font-size: 1.3rem;
    font-weight: 500;
}
 .main-title p {
    font-size: 1.3rem;
}
.logoZoom{
    background: linear-gradient(180deg, var(--secondary__color) 0%, #ffffff 100%);
    min-height: 100vh;
}
.priceTable .card-text img {
    max-width: 17px;
    background: transparent;
    margin-right: 10px;
}

.video-section video {
    background-size: cover;
    object-fit: cover;
    object-position: top center;
    height: auto;
    width: calc(100% + -2px );
    margin-left: 1px;
}
.video-section button {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    background: #0005;
    z-index: 999;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease 0.3s;
}
.bg-primary {
    background: var(--primary__color) !important;
}
.image-wrap {
    position: relative;
    overflow: hidden;
}

.image-wrap img {
    transition: all ease 0.3s;
}
.product-item a {
    color: #000;
    text-decoration: none;
}
.product-item:hover a {
    color: #fff;
}
.image-wrap:hover img {
    transform: scale(1.1);
}
.product-item span.badge {
    border-radius: 0;
    position: absolute;
    top: 16px;
    right: 17px;
    font-weight: 400;
    padding: 8px 8px;
    z-index: 2;
}
.product-item .btn.btn-link {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 20px);
    transition: all ease 0.3s;
    color: #fff;
    text-decoration: none;
}
ol, ul, dl {
    padding-left: 1rem;
}

ol>li, ul>li, dl >li {
    padding-left: 5px;
}
.custom-close {
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 5px;
}

.custom-close img {
    max-width: 35px;
}
.product-item:hover {
    background: var(--primary__color);
}
.product-item {
    overflow: hidden;
    border: none;
    border-radius: 0;
    margin-bottom: 30px;
}

.product-item:hover .btn.btn-link {
    transform: translate(-50%, -0px);
}

.product-item:hover 
 .card-body {
    background: var(--primary__color);
    color: #fff;
    transform: translateY(-40px);
}

.product-item .card-body {
    transition: all ease 0.3s;
    background:#fff;
    padding-bottom: 10px;
}
.product-item .card-body .card-text {
    margin: 0;
}
.video-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.video-section.Pause button {
    opacity: 0;
}
.navbar-toggler {
    padding: 0;
    border: none;
    margin-right: 0 !important;
}
header {
    position: sticky;
    width: 100%;
    display: inline-block;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 999;
    box-shadow: 0px 0px 10px #0003;

}
.cart-listing .img-thumb {
    max-width: 50px;
    box-shadow: 1px 2px 13px -3px #0006;
    margin-right: 20px;
    border-radius: 6px;
}
.btn-outline-primary:hover {
    background: var(--primary__color) !important;
    color: #fff !important;
}
 .nav-tabs .nav-link {
    color: #8c8c8c;
    font-weight: 400;
    padding: 2px;
    border: none;
    cursor: pointer;
}
.text-disabled {
    opacity: 0.5;
}
.price .text-decoration-line-through {
    color: #adaebd;
    margin-left: 5px;
    font-weight: 400;
}
 .nav-tabs .nav-link.active {
    color: var(--primary__color);
    border: none;
    cursor: pointer;
    font-weight: 600;
}
#online-store .nav-tabs .nav-link.active {
    border-bottom: 2px solid;
}
.support-page .nav-tabs .nav-link.active {
    border-bottom: 2px solid;
}
.v-nave .nav-link {
    padding: 17px 10px;
    font-size: 1rem;
}
.border-radius-6{
    border-radius: 6px !important;
}
.product-item .btn.btn-link img {
    filter: brightness(100);
    max-width: 18px;
}
 .nav-tabs {
    border: none;
}
#online-store .nav-tabs {
    align-items: center;
    justify-content: center;
    padding: 15px 0 20px;
}
.support-page .nav-tabs {
    align-items: center;
    justify-content: center;
    padding: 15px 0 20px;
}
.bg-light {
    background: #f8f8f8 !important;
}

.slick-dots li button:before {display: none;}

.slick-dots li button {
    width: 25px !important;
    height: 12px !important;
    background: #d1daff;
    border-radius: 20px;
}

.slick-dots li {
    width: 20px;
    height: auto;
    margin: 0 12px;
}

.slick-dots li.slick-active button {
    background: var(--primary__color);
    width: 40px !important;
    height: 15px !important;
}

.slick-dots li.slick-active {
    width: 40px;
}
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--primary__color);
    --bs-btn-border-color: var(--primary__color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--primary__color);
    --bs-btn-hover-border-color: var(--primary__color);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--primary__color);
    --bs-btn-active-border-color: var(--primary__color);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--primary__color);
    --bs-btn-disabled-border-color: var(--primary__color);
}
footer{
    background: var(--footer_bg);
}
section.video-section img {
    max-width: 130px;
}
.navbar-collapse .nav-link {
    color: #000;
    padding: 0.4rem 1.4rem !important;
    font-weight: 500;
}
 .btn-outline-primary {
    border: 1px solid var(--primary__color);
    border-radius: 20px;
    min-width: 120px;
    color: var(--primary__color) !important;
}
.btn-long{
    min-width: 120px;
}
.mw-350{
    max-width: 350px;
    margin: 0 auto;
}
.mw-450{
    max-width: 450px;
    margin: 0 auto;
}
.header-user .btn.btn-outline-primary:hover {
    background: var(--primary__color) !important;
    color: #fff !important;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: var(--primary__color) !important;
}
.btn-outline-custom {
    border: 1px solid var(--third__color);
    border-radius: 20px;
    min-width: 120px;
    color: var(--third__color) !important;
}
.btn-outline-custom:hover {
    background: var(--primary__color) !important;
    color:  var(--third__color) !important;
}

.navbar-brand img {
    max-width: 100px;
}
.scan-item img {
    width: 70px;
    height: 70px;
    object-fit: contain;
}
.scan-item h4 {
    font-size: 1.4rem;
}
*:focus-visible, button:focus {
    outline: none !important;
    box-shadow: none !important;
}
.white-box.accordion-item {
    border: none !important;
    border-radius: 10px;
    padding: 0;
}

.blog-sec .slick-slider {
    margin:0 -15px;
}
.blog-sec .slick-slide {
    padding:20px 15px;
}
.huminity img {
    max-width: 320px;
    width: 100%;
    transform: scale(1) !important;
}
.huminity img.img1 {
    animation-delay: 0.25s;
}
.huminity img.img2 {
    animation-delay: 0.5s;
}
.post-item.card {
    overflow: hidden;
    border: none;
    border-radius: 0;
}
.community .card-header {
    border-bottom: 2px solid #dfdfdf;
    background: transparent;
}
.footer-Social a {
    display: inline-block;
    margin: 5px;
    border-radius: 100%;
    overflow: hidden;
}
.footer-Social img {
    max-width: 35px;
}
.footer-apps img {
    width: 100%;
    
}
.footer-apps a {
    display: inline-block;
    width: calc(50% - 20px);
    margin-right: 10px;
    max-width: 130px;
    margin-top: 5px;
}
.footer-Social img:hover {
    filter: brightness(100);
    transform: scale(1.11);
}
.footer-Social a:hover {
    background: var(--primary__color);
}
.Facebook .comm-inner-header img {
    height: 100%;
    object-fit: contain;
    width: 100%;
}
footer h5 {
    font-weight: 600;
    font-size: 1.05rem;
    margin-bottom: 1.2rem;
}
footer .nav-link {
    padding-left: 0;
    color: #434040 !important;
    font-size: 0.9rem;
    font-weight: 500;
}
.btn.btn-primary, .btn.btn-outline-secondary {
    padding-left: 22px;
    padding-right: 22px;
}
.footer-payment img {
    max-width: 46px;
}
.blog-sec h5{
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
    display: inline-block;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    margin: 0;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 32px;
    text-transform: uppercase;
}
.btn {
    /* font-weight: 300; */
    font-size: 0.9rem;
}
.Facebook .comm-inner-header .comm-logo {
    border-radius: 100%;
    border: 1px solid #747474;
    padding: 6px;
    width: 50px;
    height: 50px;
    margin-right: 15px;
}
.comm-logo img {
    max-width: 50px;
}
.comm-inner-header small {
    position: relative;
    top: -3px;
}
.community .card-header img {
    max-width: 50px;
}
.community .card {
    border: none;
    box-shadow: 0px 2px 10px -3px #0003;
    background: #fff;
    border-radius: 10px !important;
    overflow: hidden;
}

.qr-wrap {
    position: absolute;
    left: 20%;
    top: 0;
    bottom: 0;
    right: 20%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
img.hide-circle {
    visibility: hidden;
}
.zoom-banner {
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 100%;
}
 .barcode-wrap img {
    width: 130px;
    height: 130px;
    object-fit: contain;
    padding-left: 10px;
}
.hide-circle .circle-wrap {
    opacity: 0;
}
@keyframes bubble {
    25% {
      -moz-transform: translateX(0px) translateY(0px) ;
      -webkit-transform: translateX(0px) translateY(0px);
      -o-transform: translateX(0px) translateY(0px);
      -ms-transform: translateX(0px) translateY(0px);
      transform: translateX(0px) translateY(0px) rotate(0deg);
    }
    40% {
        -moz-transform: translateX(0px) translateY(10px);
        -webkit-transform: translateX(0px) translateY(10px);
        -o-transform: translateX(0px) translateY(10px);
        -ms-transform: translateX(0px) translateY(10px);
        transform: translateX(0px) translateY(10px) rotate(2deg);
      }
    45% {
          -moz-transform: translateX(0px) translateY(10px);
          -webkit-transform: translateX(0px) translateY(10px);
          -o-transform: translateX(0px) translateY(10px);
          -ms-transform: translateX(0px) translateY(10px);
          transform: translateX(0px) translateY(10px) rotate(-2deg);
    }
    50% {
      -moz-transform: translateX(0px) translateY(10px);
      -webkit-transform: translateX(0px) translateY(10px);
      -o-transform: translateX(0px) translateY(10px);
      -ms-transform: translateX(0px) translateY(10px);
      transform: translateX(0px) translateY(10px) rotate(2deg);
    }
    55% {
        -moz-transform: translateX(0px) translateY(10px);
        -webkit-transform: translateX(0px) translateY(10px);
        -o-transform: translateX(0px) translateY(10px);
        -ms-transform: translateX(0px) translateY(10px);
        transform: translateX(0px) translateY(10px) rotate(-2deg);
      }
    60% {
        -moz-transform: translateX(0px) translateY(10px);
        -webkit-transform: translateX(0px) translateY(10px);
        -o-transform: translateX(0px) translateY(10px);
        -ms-transform: translateX(0px) translateY(10px);
        transform: translateX(0px) translateY(10px) rotate(0deg);
      }
    100% {
      -moz-transform: translateX(0px) translateY(0px);
      -webkit-transform: translateX(0px) translateY(0px);
      -o-transform: translateX(0px) translateY(0px);
      -ms-transform: translateX(0px) translateY(0px);
      transform: translateX(0px) translateY(0px) rotate(0deg);
    }
  }
  
.circle-wrap img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
footer {
    padding: 60px 0;
}
#online-store .nav-tabs .nav-item {
    padding: 0 10px;
}
.support-page .nav-tabs .nav-item {
    padding: 0 10px;
}
/* .tab-content .wow.animate__zoomIn >div {
    transform: scale(0.5);
    opacity: 0;
    transition:all ease 0.3s}
.tab-content .wow.animate__zoomIn.showItem >div {
    transform: scale(1);
    opacity: 1;
}
.tab-content .aos-init.aos-animate >div {
    transform: scale(0.5);
    opacity: 0;
    transition:all ease 0.3s}
.tab-content .aos-init.aos-animate.showItem >div {
    transform: scale(1);
    opacity: 1;
} */
.save .slick-slide.slick-active img {
    transform: scale(1.2);
}
.save .slick-slide img {
    transition: all ease 2s;
}
.animate-logo img {
    width:100%;
    height: 100%;
    object-fit: contain;
}
header .container .container-fluid {
    padding: 0;
}
.richEnd .circle-wrap {
    opacity: 0 !important;
}
.huminity .image-wrap button {
    position: absolute;
    z-index: 999;
    top: 58%;
    left: 50%;
    transition: all ease 0.4s;
    transform-origin: left;
    transform: translate(-50%, -50%);
}
.community .card-header h4 {
    font-weight: 400;
}

.blog-sec .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 72px;
    font-size: 12px;
}
.btn-primary:hover {
    background: #fff;
    color: var(--primary__color);
}
.h100vh{
    height: 100vh;
}
.sale-content button {
    min-width: 200px;
}

.bg-semiLight .sale-content h2 span {
    color: #244e64;
}
.bg-primary .sale-content h2 span,.bg-primary .sale-content h2 {
    color: #fff;
}
.bg-primary .sale-content h3 {
    color: #fff;
}
.sale-content h2 span:after, .sale-content h2 span:before {
    content: "";
    width: 55px;
    height: 1px;
    border-top: 3px solid;
    display: inline-block;
    position: relative;
    top: -11px;
    right: -20px;
}
.sale-content.text-primary h3 {
    color: #14445c;
}

.sale-content h2 span:before {
    left: -20px;
    right: auto;
}
.btn-xl {
    padding: 1rem 2.5rem !important;
    font-weight: 600;
    font-size: 1rem;
}
/* .sale-content h3 {
    font-size: 6rem;
} */
.text-light.sale-content h2 span:after, .text-light.sale-content h2 span:before {
    background: #fff;
}


@-webkit-keyframes zoomInIMG {
    0% {
        opacity: 1;
        -webkit-transform: scale3d(.4,.4,.4)  rotate(360deg);
        transform: scale3d(1,1,1)  rotate(0deg)
    }
    20% {
        opacity: 0;
        -webkit-transform: scale3d(.4,.4,.4)  rotate(360deg);
        transform: scale3d(.4,.4,.4)  rotate(360deg)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale3d(.4,.4,.4)  rotate(360deg);
        transform: scale3d(1,1,1)  rotate(-0deg)
    }
}

@keyframes zoomInIMG {
    0% {
        opacity: 1;
        -webkit-transform: scale3d(.4,.4,.4)  rotate(360deg);
        transform: scale3d(1,1,1)  rotate(0deg)
    }
    20% {
        opacity: 0;
        -webkit-transform: scale3d(.4,.4,.4)  rotate(360deg);
        transform: scale3d(.4,.4,.4)  rotate(360deg)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale3d(.4,.4,.4)  rotate(360deg);
        transform: scale3d(1,1,1)  rotate(-0deg)
    }
}
.circle-wrap img {
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-name: zoomInIMG;
    transition: all ease 0.3s;
    max-width: calc(100vh - 300px);
}
.circle_box {
    position: relative;
    display: flex;
    justify-content: center;
}
.zoomLg {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    justify-content: center;
}
.inner-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background: #0009;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    max-width: 100vw;
    /* display: none; */
}
.scene1 {
    width: 200px;
    height: 200px;
    /* border: 1px solid var(--secondary__color); */
    margin: 80px;
    perspective: 400px;
  }
  .scene1 img {
    max-width: 75px;
}
  .cube {
    width: 200px;
    height: 200px;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-100px);
    transition: transform 1s;
    animation-duration: 12000ms;
    animation-iteration-count: infinite;
    animation-name: cubeMoove;
  }
  @keyframes cubeMoove {
    0% {
        transform: rotateY( 0deg) translateZ(-100px);
    }
    16.66% {
        transform: translateZ(-100px) rotateY( -90deg);
    }
     33.32% {
        transform: translateZ(-100px) rotateY(-180deg);
    }
    49.98%{
        transform: translateZ(-100px) rotateY( 90deg);
    }
    66.64%{
        transform: translateZ(-100px) rotateX( -90deg);
    }
    83.3%{
        transform: translateZ(-100px) rotateX( 90deg);
    }
    100% {
        transform: rotateY( 0deg) translateZ(-100px);
    }
}
  .cube.show-front  { transform: translateZ(-100px) rotateY(   0deg); }
  .cube.show-right  { transform: translateZ(-100px) rotateY( -90deg); }
  .cube.show-back   { transform: translateZ(-100px) rotateY(-180deg); }
  .cube.show-left   { transform: translateZ(-100px) rotateY(  90deg); }
  .cube.show-top    { transform: translateZ(-100px) rotateX( -90deg); }
  .cube.show-bottom { transform: translateZ(-100px) rotateX(  90deg); }
  
  .cube__face {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 2px solid var(--primary__color);
    line-height: 200px;
    font-size: 40px;
    font-weight: bold;
    color: white;
    text-align: center;
  }
  .cube__face img {
    filter: brightness(1) invert(1);
  }
  .cube__face--left img ,   .cube__face--right img {
    filter: brightness(0) invert(0);
  }
  .cube__face--front  { background: rgba(152, 82, 8, 0.8); }
  .cube__face--right  { background: hsla(317, 100%, 89%, 0.8); }
  .cube__face--back   { background: hsla(77, 79%, 22%, 0.8); }
  .cube__face--left   { background: hsla(180, 100%, 50%, 0.8); }
  .cube__face--top    { background: hsl(228.24deg 87.93% 22.75% / 80%); }
  .cube__face--bottom { background: hsla(0, 98%, 22%, 0.8); }
  
  .cube__face--front  { transform: rotateY(  0deg) translateZ(100px); }
  .cube__face--right  { transform: rotateY( 90deg) translateZ(100px); }
  .cube__face--back   { transform: rotateY(180deg) translateZ(100px); }
  .cube__face--left   { transform: rotateY(-90deg) translateZ(100px); }
  .cube__face--top    { transform: rotateX( 90deg) translateZ(100px); }
  .cube__face--bottom { transform: rotateX(-90deg) translateZ(100px); }
  
 .loaderopen .allContent {
    filter: blur(10px);
    overflow-x: hidden;
    width: 100vw;
}
main.bg-light {
    min-height: calc(100vh - 77px);
} 
.header-cart {
    position: relative;
}
.btn.btn-link.link-color {
    color: var(--third__color);
}
.cart-count {
    position: absolute;
    top: -10px;
    right: -10px;
    background: var(--primary__color);
    color: #fff;
    font-size: 12px;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 20px;
}
span.loader11 {
    border-radius: 10px;
    background: var(--secondary__color);
    padding: 10px;
    transition: all ease 0.3s;
    animation: loaderbg 15s linear infinite;
}
    span.loader11 img {
        max-width: 40px;
        transition: all ease 0.3s;
        /* filter: brightness(1) invert(1); */
        animation: brightness 15s linear infinite;
    }

@keyframes brightness {
    0% {
		filter: brightness(0) invert(0);
    }
    50% {
        filter: brightness(1) invert(1);
    }
    100% {
		filter: brightness(0) invert(0);
    }
}
@keyframes loaderbg {
    0% {
        background: var(--secondary__color);
    }
    50% {
        background: var(--primary__color);
    }
    100% {
        background: var(--secondary__color);
    }
}
.loader1 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #FFF #FFF transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader1::after,
  .loader1::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent var(--primary__color) var(--primary__color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader1::before {
    width: 32px;
    height: 32px;
    border-color: #FFF #FFF transparent transparent;
    animation: rotation 1.5s linear infinite;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
      
@keyframes rotateAnimation {
	from {transform: rotate(0deg)}
	to {transform: rotate(360deg);}
} 

.loader {
    position: fixed;
    background: #96dffd;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;
    height: 100%;
}
.saveimg {
    /* animation: bubble 3s infinite; */
    -webkit-animation:hinge  3s infinite;
    animation:hinge 3s infinite;
    max-width: 500px;
}
.panel4 .saveimg {
    animation: bubble 2s infinite;
}
@-webkit-keyframes hinge{
    0%{-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out}
    20%,60%{-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out;-webkit-transform:rotate(10deg);transform:rotate(10deg)}
    40%,80%{-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out;opacity:1;-webkit-transform:rotate(0deg);transform:rotate(0deg)}
    to{opacity:0;-webkit-transform:translate3d(0,700px,0);transform:translate3d(0,700px,0)}
}
@keyframes hinge{
    0%{-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out}
    20%,60%{-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out;-webkit-transform:rotate(5deg);transform:rotate(-5deg)}
    40%,80%{-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out;opacity:1;-webkit-transform:rotate(0deg);transform:rotate(0deg)}
    to{opacity:0;-webkit-transform:translate3d(0,700px,0);transform:translate3d(0,700px,0)}
}

.loader img {
    max-width: 100px;
    animation: hors 1s infinite linear;
    transition: all ease 1s;
}
.page-title {
    padding: 40px 0px;
    background: url(./../Images/BG2x.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #000;
    background-color: #eee;
}
.primary-page-title {
    padding: 5rem 0px;
    background: url(./../Images/pagetitle2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    /* background-color: #eee; */
}
.quantity-box button img {
    max-width: 8px;
}
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}
.breadcrumb-item a {
    color: #212529bf;
}
.breadcrumb-item {
    font-size: 0.8rem;
    font-weight: 500;
}
.breadcrumb-item.active {
    color: #000;
}
.breadcrumb-item + .breadcrumb-item::before {
    font-size: 0.7rem;
    margin-top: 2px;
    transform: rotate(10deg);
}
.product-detail-label {
    font-weight: 600;
    font-size: 1.1rem;
}
.color-options .form-check-input, .label-options .form-check-input  {
    opacity: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
    height: 100%;
}
.label-options .form-check-label{
    position: relative;
    color: #000;
    opacity: 0.5;
}
.label-options .form-check {
    margin: 0px 15px 10px 0;
}
.label-options .active.form-check-label {
    opacity: 1;
}
.color-options .form-check-label {
    background: red;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid #eee;
    position: relative;
}
.color-options .form-check-label.active {
    border-color: var(--primary__color);
}
.color-options .form-check, .label-options .form-check {
    padding-left: 0;
    margin: 0 6px 6px 0;
}
.form-control:focus {
    box-shadow: none;
    border-color: #dfdfdf;
}
.accordion-button:focus{
    box-shadow: none;
}
.quantity-box input {
    border: 2px solid #dfdfdf;
    border-radius: 0;
    max-width: 40px;
    text-align: center;
}
.quantity-box button.add {
    background: transparent;
    border: 2px solid #dfdfdf;
    border-left: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 30px;
}
.bw-2{
    border-width: 2px;
}
.pin-box button {
    background: #eef1fe;
}
.pin-box input {
    border: 2px solid #dfdfdf;
    border-radius: 0;
    max-width: 120px;
    font-size: 0.8rem;
    border-top-right-radius: 6px;
    padding: 5px;
    border-bottom-right-radius: 6px;
}
.quantity-box button.sub, .pin-box button {
    background: transparent;
    border: 2px solid #dfdfdf;
    border-right: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 30px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.product-detail-content .accordion-item:first-child {
    border-top: 2px solid var(--bs-accordion-border-color) !important;
}
.product-detail-content .accordion-item{
    border-bottom: 2px solid var(--bs-accordion-border-color) !important;
}
.product-detail-content .accordion-item:first-child {
    border-top: 2px solid var(--bs-accordion-border-color) !important;
}
.product-detail-content .accordion-item{
    border-bottom: 2px solid var(--bs-accordion-border-color) !important;
}

button.accordion-button {
    background: transparent;
    padding-left: 0;
    padding-right: 0;
}

.accordion-button:not(.collapsed) {
    background: transparent;
    box-shadow: none;
    color: #000;
}
.accordion-button {
    box-shadow: none;
    font-weight: 600;
    color: #000;
}

.product-detail-content .accordion-body {
    padding-top: 0;
    font-size: 0.8rem;
    opacity: 0.5;
    padding-left: 0;
    padding-right: 0;
}
.related-title {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-left: 35px;
}

.related-title:before {
    content: "";
    background: var(--primary__color);
    width: 20px;
    height: 40px;
    border-radius: 6px;
    position: absolute;
    left: 0;
}
.thumb-item img {
    border-radius: 6px;
    border: 1px solid #fff;
    height: 100px;
    object-fit: cover;
    background: #f1f1f1;
    padding: 1px;
}
.thumb-item {
    padding: 5px;
}
.slick-slide.slick-active.slick-current img {
    border-color: var(--primary__color);
}
.hvUuMs #pinContainer .panel1 h3 {
    max-width: 600px;
}
.product-main-image img {
    /* max-height: calc(100vh - 250px); */
    /* min-height: 200px; */
    /* max-width: 400px; */
    object-fit: contain;
    object-position: center;
    /* background: #f1f1f1; */
    width: 100%;
}
section.video-section {
    /* min-height: auto !important; */
    background: #e6f8ff;
}
.vdo-wrap {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    max-width: 850px;
    margin: 0 auto;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
}

section.video-section h1 {
    font-size: calc(3rem + 5vw);
    color: #d1e3f1;margin: 0;
    text-align: center;font-weight: 700;margin-bottom: -45px;background: -webkit-linear-gradient(#d1e3f1, #f5fafc);-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
h3.section-title span {
    color: #000;
    font-weight: 300;
}
.section-heading .sm-title {
    position: relative;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
    display: inline-block;
    margin-bottom: 12px;
    padding-bottom: 12px;
    z-index: 9;
    width:200px;
}
.section-heading .sm-title:before {
    position: absolute;
    content: '';
    height: 2px;
    width: 200px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--primary__color);
}
.section-heading .sm-title:after {
    position: absolute;
    content: '';
    height: 30px;
    width: 30px;
    right: 0px;
    left: auto;
    bottom: -14px;
    animation: pulsex 5s ease infinite alternate;
    background-size: contain;
    background-image: url(./../Images/barcode.png);
}
@keyframes pulsex {
    0% {
        transform: translateX(0)
    }

    100% {
        transform: translateX(-170px)
    }
}
 .options {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    min-width: 630px;
    /* max-width: 1200px;
    width: calc(100% - 100px); */
    /* height: 324px; */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}
 .options .option {
    position: relative;
    overflow: hidden;
    width: 60px;
    max-width: 60px;
    min-height: 364px;
    margin: 10px;
    box-shadow: 0px 0px 17px -10px #000;
    background: var(--optionBackground, var(--defaultBackground, #E6E9ED));
    background-size: auto 120%;
    background-position: center;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
 .options .option.active {
    flex-grow: 10000;
    transform: scale(1);
    max-width: 600px;
    border-radius: 40px;
}

 .options .option:not(.active) {
    flex-grow: 1;
    border-radius: 30px;
}
.options .option .shadow {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    background: #000;
    opacity: 0.5;
}
.options .option.active .shadow {
    opacity: 0;
}

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .flip-card-back {
    transform: rotateY(180deg);
    position: static;
  }
 
.animate-logo {
    display: flex;
    flex-direction: column;
}
.inr-ttl h3 {
    color: #071b6d;
    margin: 0;
    visibility: visible;
    transform: scale(0) ;
}

.inr-ttl.visiblity-visible h3 {
    transform: scale(1);
    visibility: visible;
    transition: all ease 1s;
}
.logo_center {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    justify-content: center;
}
img[alt="circle"] {
    width: 100%;
    height: 100%;
    object-fit: contain;
    min-width: 45vh;
    max-width: 80%;
}
div#trigger {
    min-height: calc(100vh - 77px);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 0 !important;
}
.blog-sec .card-body small {
    font-weight: 300;
    font-size: 13px;
    color: #ccc;
}
.xsmall {
    font-size: 0.8rem;
    line-height: 1px;
}
.blog-sec .card-body {
    position: absolute;
    bottom: 0;
    background: #000000c9;
    color: #fff;
    transform: translateY(100%);
    transition: all ease 0.5s;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
}
.blog-sec .swiper-slide-active .post-item .text-start.card-body {
    transform: translateY(0px);
}
.blog-sec .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 470px;
  }
  .blog-sec .w-100 {
    height: 470px;
    object-fit: cover;
    object-position: top left;
}
/* div#trigger.animation-start {
    height: calc(100vh - 76px);
} */
.blog-sec .swiper-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)) !important;
}
.blog-sec .swiper-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)) !important;
}
.feacture-block-inner {
    padding: 35px 15px 45px;
    box-shadow: 0px 0px 10px #0003;
    border-radius: 10px;
    height: 100%;
    transition: all ease 0.3s;
}

.feacture-block-inner img {
    max-width: 80px;
    object-fit: contain;
    height: 80px;
}

.feacture-block-inner p {
    margin: 0;
}
.feacture-block-inner h4 {
    font-size: 1.2rem;
    margin-bottom: 15px;
}
.feacture-block-inner:hover {
    background: var(--primary__color);
    color: #fff;
    box-shadow: 4px 5px 20px #0006;
    transform: scale(1.05);
}
.feacture-block-inner:hover h4{
    color: #fff !important;
}
.feacture-block-inner:hover img {
    filter: brightness(100);
}
.product-detail-content .accordion-button.collapsed::after {
    background: url(./../Images/Plus.png);
}
.product-detail-content .accordion-button:not(.collapsed)::after {
    background: url(./../Images/Minus.png);
}
.product-detail-content .accordion-button:after {
    background-size: 12px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
.pin-box button {
    width: 40px;
    text-align: center;
}
.modal-header button {
    position: absolute;
    right: 14px;
    top: 15px;
}
.modal-content {
    border-radius: 13px;
    overflow: hidden;
}
.pin-box button img {
    max-width: 17px;
    object-fit: contain;
}


/* signup screen css  */
.signupImgCaption {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    top: 50px;
}
.signup-wrap h5 {
    line-height: 2rem;
}
.signupImgCaption h3 {
    max-width: 500px;
    margin: 0 auto;
    font-size: 2rem;
    padding: 15px;
}
/* ul.slick-dots {
    bottom: 30px;
} */
.signup-img {
    object-fit: cover;
    object-position: center;
    width: auto !important;
    min-width: 100%;
    max-width: 100%;
}
.signupItem {
    position: relative;
}
  .password-field span {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 15px 10px;
    line-height: 9px;
  }
  
  .password-field {
    position: relative;
  }
  
  .form-control:focus {
    box-shadow: none;
  }
  .form-control {
    /* background: #f8f8f8; */
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
}
.cursor-pointer {
    cursor: pointer;
}
select.form-control{
    background-image: url(./../Images/tringle.png);
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: 98% 53%;
}
.form-control::placeholder {
    color: #ccc;
}
  .login-container form {
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
  }
  
  .password-field span img {
    max-width: 18px;
    height: 15px;
    object-fit: contain;
    object-position: center;
  }
  
  .login-form,
  .login-content {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }
  .form-check-input:checked {
    background-color: #071b6d;
    border-color: #071b6d;
}
.form-check-input:focus{
    border-color: #071b6d;
    box-shadow: none;
}
.animation-parent{
    position: relative;
    min-height:100dvh;
}
  .signupScreen {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100dvh;
}
.signup-wrap {
    max-width: 350px;
    margin: 0 auto;
    flex: 0 0 auto;
    width: 100%;
}
.signupScreen a:not(.btn) {
    color: var(--third__color);
}
  .signupScreen .copyright {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    font-size: 12px;
}
  .password-field input {
    padding-right: 30px;
  }

  .email-verification h4 {
    font-size: 1.33em;
  }
  
  .email-verification {
    margin-left: auto;
    margin-right: auto;
    max-width: 280px;
  }
  .signup-wrap .btn.btn-primary {
    padding: 0.65rem 1.2rem;
}

.cart-listing tbody tr {
    border-bottom: 1px solid #e4e4e4;
}
.cart-listing .quantity-box input, .cart-listing .quantity-box button {
    border: none;
}
.cart-close-icon {
    color: #9f9f9f;
}
.cart-listing .quantity-box {
    border: 1px solid #ccc;
    border-radius: 20px;
    max-width: 100px;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 3px 3px 4px;
}
.cart-listing th {
    background: #efefef;
    padding: 10px 20px;
}
.coupon-form {
    background: #fff;
    padding: 15px;
    border-radius: 6px;
    box-shadow: 2px 2px 15px -3px #0003;
}

.coupon-form form {
    display: flex;
    justify-content: center;
}
.white-box .tab-content {
    padding: 0 15px;
}
.price-box, .white-box {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 2px 2px 15px -3px #0003;
    font-size: 0.9rem;
    overflow: hidden;
}
.border-box {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}
.border-box iframe {
    border-radius: 10px;
}
.price-box .row, .price-box .col {
    margin: 0;
    padding: 0;
}
.price-box .col {
    padding-top: 5px;
    padding-bottom: 5px;
}
.address-sec {
    background: #fff;
    box-shadow: 0px 0px 10px -3px #0003;
}
.address-sec .btn.btn-primary {
    margin-left: 20px;
    margin-bottom: 25px;
}
.price-box .row:last-child {
    border-top: 1px solid;
    margin-top: 10px;
    padding-top: 10px;
    font-weight: 600;
}
.coupon-form form button {
    margin-left: 15px;
}
.cart-listing {
    width: 100%;
    font-size: 0.9rem;
}

.cart-listing td {
    padding: 15px 20px;
    vertical-align: middle;
}

.cart-close-icon img {
    max-width: 10px;
    transform: rotate(45deg);
    opacity: 0.5;
}
.addNewAddress {
    border-radius: 6px;
}

.addNewAddress img {
    max-width: 12px;
    margin-right: 10px;
}

.addNewAddress:hover img {
    filter: brightness(100);
}
.customRadio {
    position: relative;
}

.customRadio input[type=radio] {
    display: none;
}
.badge.bg-secondary {
    background: #e8e8e8 !important;
    color: #888888;
    font-weight: 500;
}
label {
    font-size: 0.9rem;
}
.badge {
    border-radius: 20px;
    padding: 4px 10px;
}
.customRadio label:after {
    content: "";
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    position: absolute;
    left: 0;
    border-radius: 20px;
    top: 4px;
}
.customRadio label {
    padding-left: 27px;
}
.customRadio label:before {
    content: "";
    width: 8px;
    height: 8px;
    background: #071b6d;
    position: absolute;
    left: 4px;
    border-radius: 20px;
    top: 8px;
    opacity: 0;
}

.customRadio input:checked + label:after {
    border-color: #071b6d;
}

.customRadio input:checked + label:before {
    opacity: 1;
}
.customRadio.radio-btn label:before {
    top: 13px;
}
 .customRadio.radio-btn label:after {
    top: 9px;
}
.font-medium {
    font-weight: 500;
}
.tab-pane .cart-listing:not(:last-child) {
    border-bottom: 1px solid #ccc;
}
.tab-pane .cart-listing {
    padding: 15px 0;
}
.tag-lists .card-body {
    border-top: 1px solid #eee;
}
.tag-lists .card {
    border: none;
    box-shadow: 1px 1px 10px #0002;
}
.profile-center {
    margin-top: -75px;
}
.profileImg {
    display: inline-block;
    border-radius: 100%;
    width: 180px;
    height: 180px;
    box-shadow: 0px 0px 10px #0005;
    position: relative;
}
.profileImg .prof-wrap {
    display: inline-block;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 3px solid #fff;
    position: relative;
}
.delete-profile {
    position: absolute;
    right: 20px;
    top: 5px;
    z-index: 3;
    box-shadow: 0px 0px 5px #0003;
    border-radius: 100%;
}

span.profileImg img[alt="profile"] {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
span.profileImg input {
    position: absolute;
    width: calc(100% + 100px);
    height: 100%;
    left: -100px;
    top: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
}
span.profileImg span {
    position: absolute;
    top: 0;
    left: 0;
    background: #0005;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 1;
    border-radius: 100%;
    transition: all ease 0.3s;
    opacity: 0;
}

span.profileImg:hover span {
    opacity: 1;
}
span.customDevider {
    display: inline-block;
    width: 75%;
    border-top: 2px solid #bababa;
}
.primary-page-title h2 {
    font-weight: 500;
}
.careerAccordion button > * {
    width: 100%;
}

img.career-location {
    max-width: 13px;
    margin-right: 8px;
    margin-top: -2px;
}
.careerAccordion .accordion-header {
    border-bottom: 1px solid #e4e4e4;
}
.careerAccordion .accordion-body {
    padding: 30px;
    color: #6b6b6b;
}
.careerAccordion button p {
    font-size: 0.9rem;
    font-weight: 400;
}
.job-description li {
    margin-bottom: 5px;
}
.center-content {
    max-width: 570px;
    margin: 0 auto 15px;
}

.faqAccordion 
 .accordion-item {
    border: none;
}
.faqAccordion .accordion-button {
    /* color: var(--text__color); */
    font-weight: 500;
}

.faqAccordion {
    color: var(--text__color);
}
.faqAccordion .accordion-item .accordion-header {
    border: 1px solid #ededed;
    background: #f9f9f9;
}
.job-description h6 {
    margin-top: 30px;
}
.faqAccordion .accordion-button:after {
    width: 15px;
    height: 15px;
    background-size: contain;
    opacity: 0.8;
}
.priceTable .card {
    box-shadow: 0px 0px 10px #0003;
}
.priceTable .card {
    box-shadow: 0px 0px 10px #0003;
    border-top-left-radius: 41px;
    border-top-right-radius: 40px;
    overflow: hidden;
    height: 100%;
    padding-bottom: 5rem;
}
.priceTable .card-footer {
    position: absolute;
    bottom: 15px;
    width: 100%;
    background: transparent;
}
.priceTable .card-text {
    background: #ecedf2;
    padding: 7px 20px;
    margin-bottom: 8px;
}
.priceTable {
    height: 100%;
}
.minw-500{
    min-width: 500px;
}
.product-detail-content .coupon-form {
    box-shadow: none;
}
.form-control:disabled {
    background-color: transparent;
    opacity: 0.7;
}
.header-user img {
    max-width: 28px;
}
.header-user .btn:hover, .header-user .btn:focus,.header-user .btn:active {
    background: transparent !important;
}
.header-user .dropdown-menu {
    border: none;
    box-shadow: 0px 0px 15px -5px #0005;
    padding-top: 0;
}
.tab-pane.profile-address header {
    display: none;
}

.tab-pane.profile-address section {
    padding: 10px 0;
}

.tab-pane.profile-address .col-xl-10 {
    width: 100%;
}

.tab-pane.profile-address .col-xl-10 > div.mb-3 {
    display: none;
}
.tab-pane.profile-address .cart-listing thead {
    display: none;
}
.tab-pane.profile-address > .row > .col-sm-12 {
    padding: 0;
}
.header-user .dropdown-header {
    background: #e9edff;
    padding: 15px 15px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
.priceTable .card .card-body {
    background: #f8f8f8;
}
.form-label {
    margin-bottom: 2px;
}
.image-wrap.rounded {
    border-radius: 30px !important;
}
.comm-logo {
    height: 50px;
    display: flex;
    align-items: center;
}
.text-xxl {
    font-size: 5rem;
    line-height: 5rem;
}
.v-center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.mh-50vh{
    min-height: 40vh;
    padding: 30px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tab-pane.profile-address .bg-light {
    background: transparent !important;
}
.summeryswatch {
    background: red;
    border: 1px solid #eee;
    border-radius: 4px;
    display: inline-block;
    height: 25px;
    position: relative;
    width: 25px;
}
.prod-listing table td {
    padding: 0 10px;
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 7px;
}
.nowrap {
    white-space: nowrap;
}


.img-magnifier-container {
    position:relative;
  }
  

.img-magnifier-glass {
    position: absolute;
    border: 1px solid #000;
    border-radius: 50%;
    cursor: none;
    /*Set the size of the magnifier glass:*/
    width: 210px;
    height: 210px;
  }
  .main {
    max-width: 100vw;
    overflow-x: hidden;
}
.feed-block{
    margin-bottom: -9px;
}
.feed-block iframe {
    width: 100% !important;
}
.prod-listing table tr {
    border: none;
}
.invoice-modal{
    font-family: cambria;
    font-size: 15px;
}
.invoice-modal, .invoice-modal p {
    color: #000;
}
 .invoice-modal h6 {
    font-family: cambria;
    font-size: 1.1rem;
}
.sl-no{
    width: 60px;
}
.invoice-modal tr {
    border-color: #000;
}
.blank-table td {
    height: 200px;
}
.w-40{
    width: 295px;
}
.light-title {
    font-weight: 500;
    letter-spacing: 0.5rem;
    margin: 0;
    font-size: 1.2rem;
}
main.bg-light h3 {
    font-size: 1.9rem;
}
.prod-listing table tr td:last-child {
    min-width: 123px;
}
.document-lists .img-thumbnail {
    width: 65px;
    padding: 0;
    height: 45px;
    object-fit: cover;
    overflow: hidden;
}
.pdf-thumbnail {
    width: 65px;
    object-fit: cover;
    height: 45px;
    overflow: hidden;
    border-radius: var(--bs-border-radius) !important;
}
.pdf-thumbnail iframe {
    width: 90px;
    margin-left: -2px;
    margin-top: -2px;
}
.document-icon{
    max-width: 20px;
    object-fit: contain;
}
.btn-rounded{
    border-radius: 20px;
}
.tagNotActivated{
    position: relative;
    max-width: 300px;
    margin: 0 auto;
}
.tagNotActivated img{
    opacity: 0.5;
}
.tagNotActivated button{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50% );
}
.h-100vh{
    min-height: 100vh;
}
.qr-screen .footer-apps a {
    max-width: 200px;
    margin-bottom: 12px;
}
.mw-250{
    max-width: 250px;
    margin: 0 auto;
}
.mySwiperCard{
    max-width: 300px;
    margin: 0 auto;
    user-select: none;
}
.mySwiperCard .btn {
    position: absolute;
    bottom: 29px;
    left: 50%;
    transform: translateX(-50%);
    padding: 9px 20px;
    width: 100%;
    max-width: 206px;
    background: #001c91;
    border-color: #001c91;
}
.mySwiperCard .btn:hover,.mySwiperCard .btn:active,.mySwiperCard .btn:focus {
    background: #fff;
    border-color: #fff;
}
.mySwiperCard img{
    border-radius: 10px;
}

.calendar-container {
    display: inline-block;
    /* width: 100%; Adjust as needed */
}
  
.calendar-popup {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin-top: 5px; /* Space between input and calendar */
}

/* Tab Navigation Styling start */
.tab-navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    z-index: 1000;
    display: none; /* Hidden by default */
  }
  
  .tab-navigation a {
    color: #333;
    text-decoration: none;
    font-size: 14px;
  }
  
  .tab-navigation a.active {
    color: #007bff;
    font-weight: bold;
  }
  
/* Tab Navigation Styling start */

.fb-iframe-mobile{
    display: none;
}

.renew-tag{
    width: 27% !important;
    padding: 9px !important
}
  
.react-date-picker__wrapper{
    border: 0 !important;
}

/* responsive css  */
@media only screen and (min-width: 992px){
    .tab-pane .cart-listing .img-thumb {
        max-width: 75px;
    }
.signup-page {
    max-height: 100dvh;
    overflow: hidden;
}
.signup-img {
    max-height: 100vh;
    min-height: 100vh;
}
}
@media only screen and (min-width: 1200px){
   .signup-img {
        max-height: 100vh;
    }
    
    #online-store .nav-tabs .nav-item {
        padding: 0 20px;
    }
    #online-store .nav-tabs .nav-item a {
        font-size: 1.2rem;
    }
    .support-page .nav-tabs .nav-item a {
        font-size: 1.2rem;
    }
    .support-page .nav-tabs .nav-item {
        padding: 0 20px;
    }
    .blog-sec .slick-slider {
        margin:0 -30px;
    }
    .blog-sec .slick-slide {
        padding:20px 30px;
    }
    .min-h50{
        min-height: 50vh;
    }
}
@media only screen and (min-width: 1400px){
    .panel h1 {
        font-size: 3.2rem;
    }
    .primary-page-title {
        padding: 7rem 0px;
    }
    .primary-page-title h2 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .primary-page-title p {
        font-size: 1.2rem;
    }
    img.img-fluid.signup-img {
        max-height: 100vh;
    }
    #online-store .nav-tabs .nav-item {
        padding: 0 27px;
    }
    #online-store .nav-tabs .nav-item a {
        font-size: 1.3rem;
    }
}
@media only screen and (max-width: 1440px){
    .options .option.active {
        max-width: 500px;
    }
    .signupImgCaption {
        top: 20px;
    }
    .options .option {
        min-height: 303px;
    }
    .section-heading .sm-title:after{
        height: 22px;
        width: 22px;
        bottom: -9px;
    }
    .options .option {
        margin: 5px;
    }
    .main-title h2 {
        font-size: 2rem;
        max-width: 600px;
    }
    .text-xxl {
        font-size: 4rem;
        line-height: 4rem;
    }
    .main-title p {
        font-size: 0.9rem;
    }
    .section-title {
        font-size: 2rem;
    }
    .barcode-wrap img {
        width: 110px;
    } 
}

@media only screen and (max-width: 1280.5px){
.options .option.active {
    max-width: 350px;
}

.options .option {
    min-height: 212px;
    max-width: 40px;
}
.main-title h2 {
    font-size: 2.2rem;
    max-width: 600px;
}
.main-title p {
    font-size: 1rem;
}
/* .sale-content h3 {
    font-size: 5rem;
} */
.sale-content h2 span {
    font-size: 2rem;
}

}
@media only screen and (max-width: 1024px){
    .barcode-wrap img {
        width: 100px;
    }    
    .signupImgCaption {
        top: 60px;
    }
    .sale-content h2 span:after, .sale-content h2 span:before {
        width: 45px;
    }
    .sale-content h2 span {
        font-size: 1.8rem;
    }
}
@media only screen and (max-width: 991.5px){
    span.navbarShadow.actMenu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    span.navbarShadow.actMenu + div {
        position: relative;
        z-index: 22;
    }
    #online-store .nav-tabs .nav-link {
        font-size: 0.9rem;
    }
    .v-nave .nav-link {
        padding: 7px 10px;
    }
    .delete-profile {
        right: 3px;
        top: 3px;
    }
    .modal-dialog.modal-lg.invoice-modal {
        max-width: calc(100% - 10px);
    }
    .signup-page .p-0.col-lg-6 {
        display: none;
    }
    .text-xxl {
        font-size: 3rem;
        line-height: 3rem;
    }
    .huminity .image-wrap button {
        padding-left: 0.7rem !important;
        padding-right: 0.7rem !important;
    }
    .profileImg {
        width: 120px;
        height: 120px;
    }
    .profile-center {
        margin-top: -50px;
    }
    img[alt="circle"] {
        min-width: 400px !important;
    }
    section.video-section h1 {
        font-size: calc(2rem + 5vw);
        margin-bottom: -20px;
    }        
    .qr-wrap {
        left: 10%;
        right: 10%;
    }
    .options .option {
        min-height: 182px;
        max-width: 30px;
    }
    .optionsWrap {
        width: 100%;
        overflow: auto;
    }
    .slickCenterMode .container {
        max-width: 100%;
    }
    .options .option.active {
        max-width: 300px;
        border-radius: 20px;
        box-shadow: 0px 0px 10px -5px #0005;
    }
    #online-store .nav.nav-tabs {
    white-space: nowrap;
    flex-wrap: nowrap;
    padding-top: 0;
    overflow: auto;
    justify-content: flex-start;
    padding-bottom: 7px;
}
.support-page  .nav.nav-tabs {
    white-space: nowrap;
    flex-wrap: nowrap;
    padding-top: 0;
    overflow: auto;
    justify-content: flex-start;
    padding-bottom: 7px;
}
.saveimg{
    max-width: 400px;
}
.navbar-toggler {
    padding: 0;
    border: none;
    margin-right: 0 !important;
}
.navbar-toggler:focus {
    box-shadow: none;
}
.navbar-collapse {
    text-align: left;
    padding: 10px 0;
}
.slick-dots li {
    width: 20px;
    margin: 0 3px;
}
.slick-dots li button {
    width: 20px !important;
    height: 4px !important;
}
.slick-dots li.slick-active button {
    background: var(--primary__color);
    width: 30px !important;
    height: 10px !important;
}
.slick-dots li.slick-active {
    width: 30px;
}
header ul.me-0.ms-0.nav li {
    margin-right: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
}
.navbar-collapse .nav-link {
    padding: 6px 6px !important;
}
footer {
    padding: 40px 0;
}
footer h5 {
    margin-top: 15px;
    margin-bottom: 5px;
}

.section-heading {
    padding-bottom: 1rem;
}
section {
    padding: 40px 0;
}
.section-title {
    font-size: 2rem;
}
.main-title h2{
    font-size: 1.7rem;
    max-width: 400px;
}
/* .video-section video {
    height: 400px;
} */
.main-title p {
    font-size: 0.8rem;
}
.sale-content h2 span:after, .sale-content h2 span:before {
    width: 35px;
    top:0;
    height: 5px;
}
.sale-content h2 span {
    font-size: 1rem;
    margin-top: 1rem;
    display: inline-block;
}
}
@media only screen and (max-width: 767.5px){

    .renew-tag{
        width: 42% !important;
        padding: 9px !important
    }

    .fb-iframe-web {
        display: none;
    }
    
    .fb-iframe-mobile{
        display: block;
    }

    /* dl>li, ol>li,  */
    .mobile-view__blogcontainer{
        overflow: auto;
        white-space: nowrap;
    }

    .mobile-view{
        max-width: 68%;
    }

    .saveimg {
        max-width: 200px;
    }

    .profile-tab-link {
        padding-left: 5px;
        background: aliceblue;
        border-radius: 10px;
        padding: 5px;
        margin: 5px;
        padding: 12px 10px;
    }

    .nav-tabs .nav-link.active {
        border: none;
        color: #071b6d;
        color: var(--primary__color);
        cursor: pointer;
        font-weight: 600;
        border-bottom: 2px solid;
        background: aliceblue;
    }

    #online-store .nav-tabs .nav-item, .support-page .nav-tabs .nav-item {
        padding: 7px;
        border-radius: 10px;
        background: aliceblue;
        border: 1px solid blue;
        margin: 5px;
    }

    #online-store .nav-tabs .nav-link {
        font-size: .9rem;
        background: aliceblue;
    }

    #online-store .nav.nav-tabs, .support-page .nav.nav-tabs {
        flex-wrap: wrap;
        justify-content: flex-start;
        overflow: auto;
        padding-bottom: 7px;
        padding-top: 0;
        white-space: nowrap;
        display: flex;
        align-items: stretch;
    }

    .tab-navigation {
        display: flex;
    }
    .blog-sec h5 a, .blog-sec .card-text {
        min-height: auto;
    }
    .profilebg {
        min-height: 120px;
        object-fit: cover;
        object-position: center;
    }
    .cart-listing td,.cart-listing th {
        padding: 15px 10px;
    }
    .cart-listing .img-thumb {
        margin-right: 10px;
    }
    .cart-listing .quantity-box {
        max-width: 90px;
    }
    .cart-listing td:first-child, .cart-listing th:first-child {
        width: 100%;
    }
    .signupImgCaption {
        top: 50px;
    }
    .signupImgCaption h3 {
        font-size: 1.5rem;
        max-width: 400px;
    }
    .sale-content h3 {
        font-size: 1rem;
    }
    .btn-xl {
        padding: 0.8rem 2.1rem !important;
        font-weight: 600;
        font-size: 1rem;
    }   
    .section-heading p {
        font-size: 1rem;
    }
    .animate-logo img {
        max-width: 300px;
    }
    .blog-sec .slick-slide {
        padding-top: 0px;
    }
    .mobile-tab-view {
        display: ruby;
    }
    #pinContainer {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

  #pinContainer .panel {
    height: 100vh;
    width: 100vw;
    position: absolute;
    text-align: center;
  }
/* .video-section video {
    height: 300px;
} */
}
@media only screen and (max-width: 550.5px){

    .saveimg {
        max-width: 200px;
    }

    .main-title h2 {
        font-size: 1.4rem;
        max-width: 350px;
    }
    .main-title p {
    font-size: 0.7rem;
}
.signupImgCaption {
    top: 30px;
}
.mobile-tab-view {
    display: ruby;
}
}
@media only screen and (max-width: 480.5px){

    .saveimg {
        max-width: 200px;
    }
    
    section.video-section h1 {
        font-size: calc(1.7rem + 5vw);
        margin-bottom: -12px;
    }
    .main-title h2 {
        font-size: 1.3rem;
    }
    img[alt="circle"] {
        min-width: 300px !important;
    }
    .barcode-wrap img {
        width: 100px;
    }
    .qr-wrap {
        left: 0;
        right: 0;
    }
    .section-title {
        font-size: 1.4rem;
        margin-bottom: 5px;
    }
    /* .video-section video {
        height: 200px;
    } */
    .mobile-tab-view {
        display: ruby;
    }
   
}

@media only screen and (max-width: 375.5px){
    .signupImgCaption h3 {
        font-size: 1.2rem;
    }
    .signupImgCaption {
        top: 5px;
    }
    .mobile-tab-view {
        display: ruby;
    }
}