:root{
    
      --icon-color1: #fc0;
      --icon-color2: rgb(162, 0, 255);
      --icon-color3: rgb(255, 0, 0);
      --icon-color4: rgb(14, 210, 0);
      --icon-color5: hsl(215, 100%, 54%);
      --icon-color6: #5caee8;
      --icon-color7 : #071b6d;
  }
  .animation-container {
      height: 100%;
      overflow: hidden;
      position: absolute;
      width: 100%;
      max-height: calc(100dvh - 77px);
      z-index: 99;
      top: 0;
      left: 0;
  }
  .animation-container.hide {
      z-index: -1;
  }
  @-webkit-keyframes balloons {
      0% {
            top: 100%;
            -webkit-transform: rotate(-4deg);
            transform: rotate(var(--rotate-initial));
            left: 50%;
      }
      10% {
            top:  -5%;
            -webkit-transform: rotate(4deg);
            transform: rotate(-(var(--rotate-initial))); 
            transition: all ease 15s;
            left: var(--left-initial);
            opacity: 0.8;
      } 
      100% {
            top: 100%;
            -webkit-transform: rotate(-4deg);
            transform: rotate(var(--rotate-initial));  
            transition: all ease 15s;
            left: var(--left-initial);
            opacity: 0.3;
      }
}

@keyframes balloons {
      0% {
            top: 100%;
            -webkit-transform: rotate(-4deg);
            transform: rotate(var(--rotate-initial)) scale(0.5);
            left: 50%;
      }
      10% {
            top:  -5%;
            -webkit-transform: rotate(4deg);
            transform: rotate(-(var(--rotate-initial))) scale(0.4); 
            transition: all ease 15s;
            left: var(--left-initial);
            opacity: 1;
      } 
      100% {
            top: 100%;
            -webkit-transform: rotate(-4deg);
            transform: rotate(var(--rotate-initial)) scale(0.5);  
            transition: all ease 15s;
            left: var(--left-initial);
            opacity: 0.5;
      }
}
  .animation-container .curved:after, .animation-container .curved:before {
        content:"";
      width: 50px;
      height: 25px;
      border: solid 5px var(--icon-color);
      border-color: var(--icon-color) transparent transparent transparent;
      border-radius: 50%/25px 25px 0 0;
      position: absolute;
      left: 0;
      top: 0;
  }
  .animation-container .curved {
      width: 10px;
      height: 100px;
      position: absolute;
      left: 0%;
      top:100%;
      -webkit-transition: -webkit-transform 0.5s ease;
      transition: -webkit-transform 0.5s ease;
      -o-transition: transform 0.5s ease;
      transition: transform 0.5s ease;
      transition: transform 0.5s ease, -webkit-transform 0.5s ease;
      z-index: 10;
      -webkit-animation: balloons 2s;
      animation: balloons 2s;
      -webkit-transform-origin: bottom center;
      -ms-transform-origin: bottom center;
      transform-origin: bottom center; 
      transform: rotate(45deg) scale(0.6);
  }
  .animation-container .curved:before{
        left: 39px;
      transform: rotate(183deg);
      top: 1px;
  }
  /* Star css  */
  .animation-container .star {
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .9em;
    margin-right: .9em;
    margin-bottom: 1.2em;
    border-right:  .3em solid transparent;
    border-bottom: .7em  solid var(--icon-color);
    border-left:   .3em solid transparent;
    /* Controlls the size of the stars. */
    font-size: 10px;
  
    -webkit-transition: -webkit-transform 0.5s ease;
      transition: -webkit-transform 0.5s ease;
      -o-transition: transform 0.5s ease;
      transition: transform 0.5s ease;
      transition: transform 0.5s ease, -webkit-transform 0.5s ease;
      z-index: 10;
      -webkit-animation: balloons 3s ease-in-out  normal;
      animation: balloons 3s ease-in-out  normal;
      -webkit-transform-origin: bottom center;
      -ms-transform-origin: bottom center;
      transform-origin: bottom center; 
      top: 100%;
      position: absolute;
      left: 0%;
  }
  .animation-container .star:before, .star:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      
      position: absolute;
      top: .6em;
      left: -1em;
    
      border-right:  1em solid transparent;
      border-bottom: .7em  solid var(--icon-color);
      border-left:   1em solid transparent;
    
      transform: rotate(-35deg);
  }
  
  /* circle css  */
  .animation-container .circle{
    background: var(--icon-color);
    border-radius: 100%;
    width: 20px;
    height: 10px;
    -webkit-transition: -webkit-transform 0.5s ease;
      transition: -webkit-transform 0.5s ease;
      -o-transition: transform 0.5s ease;
      transition: transform 0.5s ease;
      transition: transform 0.5s ease,
       -webkit-transform 0.5s ease;
      z-index: 10;
      -webkit-animation: balloons 3s ease-in-out  normal;
      animation: balloons 3s ease-in-out  normal;
      -webkit-transform-origin: bottom center;
      -ms-transform-origin: bottom center;
      transform-origin: bottom center; 
      top: 100%;
      position: absolute;
      left: 50%;
  }
  .animation-container .star:after {  
      transform: rotate(35deg);
  }

  .animation-container .balloon {
      display: inline-block;
      width: 20px;
      height: 27px;
      background: var(--icon-color);
      border-radius: 80%;
      left: 50%;
      top: 100%;
      position: absolute;
      opacity: 0.8;
      -webkit-box-shadow: inset -5px -4px 0 rgba(0, 0, 0, 0.2);
      box-shadow: inset -5px -4px 0 rgba(0, 0, 0, 0.2);
      margin: 20px 30px;
      -webkit-transition: -webkit-transform 0.5s ease;
      transition: -webkit-transform 0.5s ease;
      -o-transition: transform 0.5s ease;
      transition: transform 0.5s ease;
      transition: transform 0.5s ease, -webkit-transform 0.5s ease;
      z-index: 10;
      -webkit-animation: balloons 2s;
      animation: balloons 2s;
      -webkit-transform-origin: bottom center;
      -ms-transform-origin: bottom center;
      transform-origin: bottom center; 
  }
  .animation-container .balloon:before {
        content: "▲";
        font-size: 10px;
        color: var(--icon-color);
        display: block;
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: -8px;
        z-index: -100; 
  }
  .animation-container .star:nth-child(1) {
        animation-duration: 5s; 
        --rotate-initial: 360deg;
        --left-initial: 42%;
        --icon-color: var(--icon-color1);
  }
  .animation-container .star:nth-child(2) {
        animation-duration: 8.5s; 
        --rotate-initial: 300deg;
        --left-initial: 50%;
        --icon-color:var(--icon-color2);
  }
  .animation-container .star:nth-child(3) {
        animation-duration: 7s;
        --rotate-initial: 260deg;
        --left-initial: 47%;
        --icon-color: var(--icon-color3);
  }
  .animation-container .star:nth-child(4) {
        animation-duration: 7.5s; 
        --rotate-initial: 360deg;
        --left-initial: 53%; 
        --icon-color: var(--icon-color4);
  }
  .animation-container .star:nth-child(5) {
        animation-duration: 8.5s; 
        --rotate-initial: 360deg;
        --left-initial: 55%;
        --icon-color:var(--icon-color5);
  }
  .animation-container .star:nth-child(6) {
        animation-duration: 4.5s; 
        --rotate-initial: 360deg;
        --left-initial: 60%;
        --icon-color:var(--icon-color6);
  }
  .animation-container .star:nth-child(7) {
        animation-duration: 7.8s; 
        --rotate-initial: 350deg;
        --left-initial: 65%;
        --icon-color:var(--icon-color7);
  }
  .animation-container .star:nth-child(8) {
        animation-duration: 6.5s;
        --rotate-initial: 360deg;
        --left-initial: 61%;
        --icon-color: var(--icon-color1);
  }
  .animation-container .star:nth-child(9) {
        animation-duration: 7.3s;
        --rotate-initial: 360deg;
        --left-initial: 55%;
        --icon-color: var(--icon-color2);
  }
  .animation-container .star:nth-child(10) {
        animation-duration: 7s;
        --rotate-initial: 300deg;
        --left-initial: 50%;
        --icon-color: var(--icon-color3);
  }
  .animation-container .star:nth-child(11) {
        animation-duration: 11.5s; 
        --rotate-initial: 360deg;
        --left-initial: 45%; 
        --icon-color: var(--icon-color4);
  }
  .animation-container .star:nth-child(12) {
        animation-duration: 8.5s; 
        --rotate-initial: 360deg;
        --left-initial: 40%;
        --icon-color:var(--icon-color5);
  }
  .animation-container .star:nth-child(13) {
        animation-duration: 10.5s; 
        --rotate-initial: 360deg;
        --left-initial: 42%;
        --icon-color:var(--icon-color6);
  }
  .animation-container .star:nth-child(14) {
        animation-duration: 7.8s; 
        --rotate-initial: 360deg;
        --left-initial: 58%;
        --icon-color:var(--icon-color7);
  }
  .animation-container .circle:nth-child(15) {
        animation-duration: 5.5s;
        --rotate-initial: 300deg;
        --left-initial: 40%;
        --icon-color: var(--icon-color1);
  }
  .animation-container .circle:nth-child(16) {
        animation-duration: 7.5s;
        --rotate-initial: 360deg;
        --left-initial: 45%;
        --icon-color: var(--icon-color2);
  }
  .animation-container .circle:nth-child(17) {
        animation-duration: 6.5s;
        --rotate-initial: 360deg;
        --left-initial: 49%;
        --icon-color: var(--icon-color3);
  }
  .animation-container .circle:nth-child(18) {
        animation-duration: 10.5s;
        --rotate-initial: 300deg;
        --left-initial: 51%;
        --icon-color: var(--icon-color4);
  }
  .animation-container .circle:nth-child(19) {
        animation-duration: 4.5s;
        --rotate-initial: 360deg;
        --left-initial: 54%;
        --icon-color: var(--icon-color5);
  }
  .animation-container .circle:nth-child(20) {
        animation-duration: 6.5s;
        --rotate-initial: 360deg;
        --left-initial: 58.5%;
        --icon-color: var(--icon-color6);
  }
  .animation-container .circle:nth-child(21) {
        animation-duration:7.5s;
        --rotate-initial: 300deg;
        --left-initial: 61.51%;
        --icon-color: var(--icon-color7);
  }
  .animation-container .circle:nth-child(22) {
        animation-duration: 8.5s;
        --rotate-initial: 300deg;
        --left-initial: 66%;
        border-radius: 0;
        --icon-color: var(--icon-color1);
  }
  .animation-container .circle:nth-child(23) {
        animation-duration: 7.3s;
        --rotate-initial: 300deg;
        --left-initial: 61%;
        border-radius: 0;
        --icon-color: var(--icon-color2);
  }
  .animation-container .circle:nth-child(24) {
        animation-duration: 8.5s;
        --rotate-initial: 300deg;
        --left-initial: 55%;
        border-radius: 0;
        --icon-color: var(--icon-color3);
  }
  .animation-container .circle:nth-child(25) {
        animation-duration: 7.6s;
        --rotate-initial: 300deg;
        --left-initial: 51%;
        border-radius: 0;
        --icon-color: var(--icon-color4);
  }
  .animation-container .circle:nth-child(26) {
        animation-duration: 7.5s;
        --rotate-initial: 300deg;
        --left-initial: 43%;
        border-radius: 0;
        --icon-color: var(--icon-color5);
  }
  .animation-container .circle:nth-child(27) {
        animation-duration: 5.5s;
        --rotate-initial: 300deg;
        --left-initial: 39%;
        border-radius: 0;
        --icon-color: var(--icon-color6);
  }
  .animation-container .circle:nth-child(28) {
        animation-duration: 5.5s;
        --rotate-initial: 300deg;
        --left-initial: 47%;
        border-radius: 0;
        --icon-color: var(--icon-color7);
  }
  .animation-container .circle:nth-child(29) {
        animation-duration: 4.5s;
        --rotate-initial: 360deg;
        --left-initial: 41%;
        border-radius: 0;
        width:10px;
        --icon-color: var(--icon-color1);
  }
  .animation-container .circle:nth-child(30) {
        animation-duration: 6.5s;
        --rotate-initial: 360deg;
        --left-initial: 44%;
        border-radius: 0;
        width: 10px;
        --icon-color: var(--icon-color2);
  }
  .animation-container .circle:nth-child(31) {
        animation-duration: 7.5s;
        --rotate-initial: 360deg;
        --left-initial: 51%;
        border-radius: 0;
        width:10px;
        --icon-color: var(--icon-color3);
  }
  .animation-container .circle:nth-child(32) {
        animation-duration: 6.5s;
        --rotate-initial: 360deg;
        --left-initial: 53%;
        border-radius: 0;
        width: 10px;
        --icon-color: var(--icon-color4);
  }
  .animation-container .circle:nth-child(33) {
        animation-duration: 8.5s;
        --rotate-initial: 360deg;
        --left-initial: 56%;
        border-radius: 0;
        width:10px;
        --icon-color: var(--icon-color5);
  }
  .animation-container .circle:nth-child(34) {
        animation-duration: 10.5s;
        --rotate-initial: 360deg;
        --left-initial: 62%;
        border-radius: 0;
        width: 10px;
        --icon-color: var(--icon-color6);
  }
  .animation-container .circle:nth-child(35) {
        animation-duration: 9.5s;
        --rotate-initial: 360deg;
        --left-initial: 53%;
        border-radius: 0;
        width:10px;
        --icon-color: var(--icon-color7);
  }
  
  .animation-container .balloon:nth-child(36) {
        animation-duration: 11.5s;
        --rotate-initial: 50deg;
        --left-initial: 40%;
        --icon-color: var(--icon-color1);
  }
  .animation-container .balloon:nth-child(37) {
        animation-duration: 9.5s;
        --rotate-initial: 300deg;
        --left-initial: 45%;
        --icon-color: var(--icon-color2);
  }
  .animation-container .balloon:nth-child(38) {
        animation-duration: 12s;
        --rotate-initial: 50deg;
        --left-initial: 50%;
        --icon-color: var(--icon-color3);
  }
  .animation-container .balloon:nth-child(39) {
        animation-duration: 9.5s;
        --rotate-initial: 300deg;
        --left-initial: 55%;
        --icon-color: var(--icon-color4);
  }
  .animation-container .balloon:nth-child(40) {
        animation-duration: 7.5s;
        --rotate-initial: 50deg;
        --left-initial: 60%;
        --icon-color: var(--icon-color5);
  }
  .animation-container .balloon:nth-child(41) {
        animation-duration: 8.5s;
        --rotate-initial: 300deg;
        --left-initial: 45%;
        --icon-color: var(--icon-color6);
  }
  .animation-container .balloon:nth-child(42) {
        animation-duration: 10s;
        --rotate-initial: 360deg;
        --left-initial: 53%;
        --icon-color: var(--icon-color7);
  }
  .animation-container .curved:nth-child(43) {
        animation-duration: 9.5s;
        --rotate-initial: 90deg;
        --left-initial: 40%;
        --icon-color: var(--icon-color1);
  }
  .animation-container .curved:nth-child(44) {
        animation-duration: 12.5s;
        --left-initial: 55%;
        --rotate-initial: 180deg;
        --icon-color: var(--icon-color2);
  }
  .animation-container .curved:nth-child(45) {
        animation-duration: 11.5s;
        --rotate-initial: 360deg;
        --left-initial: 50%;
        --icon-color: var(--icon-color3);
  }
  .animation-container .curved:nth-child(46) {
        animation-duration: 7.5s;
        --left-initial: 55.5%;
        --rotate-initial: 90deg;
        --icon-color: var(--icon-color4);
  }
  .animation-container .curved:nth-child(47) {
        animation-duration: 14.5s;
        --left-initial: 60.5%;
        --rotate-initial: 50deg;
        --icon-color: var(--icon-color5);
  }
  .animation-container .curved:nth-child(48) {
        animation-duration: 12.5s;
        --left-initial: 43.5%;
        --rotate-initial: 0deg;
        --icon-color: var(--icon-color6);
  }
  .animation-container .curved:nth-child(49) {
        animation-duration: 5.5s;
        --left-initial: 60.5%;
        --rotate-initial: 50deg;
        --icon-color: var(--icon-color7);
  }
  